import { apiResetPassword } from 'SharedComponents/password/api';
import { apiValidatePasswordCode } from '../api';

export const VALIDATE_PASSWORD_CODE_INIT = 'PASSWORD_RESET.SEND_SMS_CODE.VALIDATE_PASSWORD_CODE_INIT';
export const VALIDATE_PASSWORD_CODE_COMPLETE = 'PASSWORD_RESET.SEND_SMS_CODE.VALIDATE_PASSWORD_CODE_COMPLETE';
export const UPDATE_CODE_VALUE = 'PASSWORD_RESET.SEND_SMS_CODE.UPDATE_CODE_VALUE';
export const UPDATE_USER_VALUE = 'PASSWORD_RESET.SEND_SMS_CODE.UPDATE_USER_VALUE';
export const RESEND_PASSWORD_CODE_INIT = 'PASSWORD_RESET.SEND_SMS_CODE.RESEND_PASSWORD_CODE_INIT';
export const RESEND_PASSWORD_CODE_COMPLETE = 'PASSWORD_RESET.SEND_SMS_CODE.RESEND_PASSWORD_CODE_COMPLETE';

export const validatePasswordCodeInit = () => ({
  type: VALIDATE_PASSWORD_CODE_INIT,
});

export const validatePasswordCodeComplete = () => ({
  type: VALIDATE_PASSWORD_CODE_COMPLETE,
});

export const updateCodeValue = (code) => ({
  type: UPDATE_CODE_VALUE,
  code,
});

export const updateUserValue = (user) => ({
  type: UPDATE_USER_VALUE,
  user,
});

export const resendPasswordCodeInit = () => ({
  type: RESEND_PASSWORD_CODE_INIT,
});

export const resendPasswordCodeComplete = () => ({
  type: RESEND_PASSWORD_CODE_COMPLETE,
});

export const resendPasswordCode = (endpoint, user, onSuccessCallback) => (dispatch) => {
  dispatch(resendPasswordCodeInit());

  const successResponseHandler = () => {
    dispatch(resendPasswordCodeComplete());
    onSuccessCallback();
  };

  const errorResponseHandler = () => dispatch(resendPasswordCodeComplete());

  apiResetPassword(endpoint, user)
    .then(successResponseHandler)
    .catch(errorResponseHandler);
};

export const validatePasswordCode = (endpoint, params, onSuccessCallback) => (dispatch) => {
  dispatch(validatePasswordCodeInit());

  const successResponseHandler = (data) => {
    dispatch(validatePasswordCodeComplete());
    onSuccessCallback(data);
  };

  const errorResponseHandler = () => {
    dispatch(validatePasswordCodeComplete());
  };

  apiValidatePasswordCode(endpoint, params)
    .then((data) => successResponseHandler(data))
    .catch(errorResponseHandler);
};

const initialState = {
  code: null,
  user: null,
  passwordCodeIsValidating: false,
  passwordCodeIsResending: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case VALIDATE_PASSWORD_CODE_INIT:
      return {
        ...state,
        passwordCodeIsValidating: true,
      };
    case VALIDATE_PASSWORD_CODE_COMPLETE:
      return {
        ...state,
        passwordCodeIsValidating: false,
      };
    case UPDATE_CODE_VALUE:
      return {
        ...state,
        code: action.code,
      };
    case UPDATE_USER_VALUE:
      return {
        ...state,
        user: action.user,
      };
    case RESEND_PASSWORD_CODE_INIT:
      return {
        ...state,
        passwordCodeIsResending: true,
      };
    case RESEND_PASSWORD_CODE_COMPLETE:
      return {
        ...state,
        passwordCodeIsResending: false,
      };
    default:
      return state;
  }
};
